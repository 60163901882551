<template>
  <div>
    <Form />
  </div>
</template>

<script>
import Form from '../components/Form.vue';

export default {
  name: 'AdminUsersCreate',
  components: {
    Form,
  },
}
</script>
