<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="4">
          <b-form-group label="Nome" label-for="user-input-name">
            <b-form-input id="user-input-name" placeholder="Nome" v-model="user.name"/>
          </b-form-group>
        </b-col>
        <b-col md="5">
          <b-form-group label="Email" label-for="mc-email">
            <b-form-input id="mc-email" placeholder="Email" v-model="user.email"/>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Telefone" label-for="mc-phone">
            <b-form-input id="mc-phone" placeholder="99 99999-9999" v-model="user.phone"/>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Tipo de usuário" label-for="extract-filter-status">
            <v-select v-if="canEditType()"
              id="extract-filter-type" 
              :options="typeOptions" 
              v-model="user.user_type" 
              :reduce="(value) => value.value" 
            />
            <b-form-input v-else id="extract-filter-type" :value="user.user_type" disabled/>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Status" label-for="extract-filter-status">
            <v-select 
              id="extract-filter-status" 
              :options="statusOptions" 
              v-model="user.status" 
              :reduce="(value) => value.value" 
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Senha" label-for="user-input-password">
            <b-form-input id="user-input-password" placeholder="Senha" type="password" v-model="user.password"/>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Confirmar senha" label-for="user-input-password">
            <b-form-input id="user-input-password-confirmation" placeholder="Confirmar senha" type="password" v-model="user.password_confirmation"/>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="d-flex justify-content-end">
          <b-button type="reset" variant="primary-secondary" @click="back">
            Voltar
          </b-button>
          <b-button type="submit" variant="primary" class="mr-1" @click="save">
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import EventBus from "@/services/event_bus";
import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'AdminUsersForm',
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  data() {
    return {
      user: {
        id: null,
        name: '',
        email: '',
        phone: '',
        status: 'active',
        user_type: 'admin',
        password: '',
        password_confirmation: '',
      },
      statusOptions: [
        { value: 'active', label: 'Ativo' },
        { value: 'inactive', label: 'Inativo' },
        { value: 'blocked', label: 'Bloqueado' }
      ],
      typeOptions: [
        { value: 'admin', label: 'Administrador' },
        { value: 'parceiro', label: 'Parceiro' }
      ]
    }
  },
  mounted() {
    this.fetchUser();
  },
  methods: {
    save() {
      console.log(this.user)
      if (this.user.id) return this.update();
      this.create();
    },
    create () {
      try {
        this.$store.dispatch("admin/createUser", this.user).then((response) => {
          this.$swal.fire({
            title: 'Sucesso!',
            text: 'Usuário cadastrado com sucesso!',
            icon: 'success',
            confirmButtonText: 'Ok',
          });
          this.$router.push({ name: "admin-users.index" });
        });
      } catch (e) {
        this.$swal.fire({
          title: 'Erro!',
          text: 'Não foi possível cadastrar o usuário!',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }
    },
    update () {
      try {
        this.$store.dispatch("admin/updateUser", this.user).then((response) => {
          this.$swal.fire({
            title: 'Sucesso!',
            text: 'Usuário atualizado com sucesso!',
            icon: 'success',
            confirmButtonText: 'Ok',
          });
          this.$router.push({ name: "admin-users.index" });
        });
      } catch (e) {
        this.$swal.fire({
          title: 'Erro!',
          text: 'Não foi possível atualizar o usuário!',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }
    },
    async fetchUser () {
      const id = this.$route.params.id;
      if (!id) return;
      const { data } = await this.$store.dispatch("admin/fetchUser", id);
      this.user = data;
    },
    canEditType () {
      return !this.user.id || this.typeOptions.map(option => option.value).includes(this.user.user_type);
    },
    back () {
      this.$router.push({ name: "admin-users.index" });
    }
  },
  computed: {},
  watch: {},
}
</script>